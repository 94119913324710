<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row>
                    <a-col :span="24" style="text-align: left">
                        <a-date-picker mode="year" @openChange="openChange" :defaultValue="momentYear?momentYear:null"
                                       :value="momentYear?momentYear:null" :open="isOpen" format="YYYY"
                                       @panelChange="handleChange"/>
                    </a-col>
					<a-col :span="24" style="margin-top: 20px">
						<a-spin :spinning="isLoading" style="position: relative;">
							<div
							    style="width:100%;height: 1px;border-top:1px solid #e8e8e8;position: absolute;z-index: 2;background-color: white;">
							
							</div>
							<div style="width:100%;overflow-y: auto;height: 100%;table-layout: fixed;z-index: 1;" :style="styObj">
							    <table style="text-align: center;width: 100%;" id="tables">
							        <thead>
							            <th style="border:1px solid #e8e8e8;height: 50px;text-align: center;vertical-align:middle;width: 140px;height: 50px;position: sticky;top: 0;;background: #ffffff;table-layout: fixed;"
							                class="body2">{{l('levelName')}}</th>
							            <th style="border:1px solid #e8e8e8;height: 50px;text-align: center;vertical-align:middle;width: 120px;height: 50px;position: sticky;top: 0;background: #ffffff;"
							                class="body1">{{l('month')}}</th>
							            <th v-for="item in month" :title="item"
							                style="border:1px solid #e8e8e8;vertical-align:middle;position: sticky;top: 0;background: #ffffff;"
							                class="body">{{item}}</th>
							        </thead>
							
							        <tr v-for="(item, number) in LevelList" v-if="number != (LevelList.length - 1)">
							            <td style="border:1px solid #e8e8e8;width: 160px;height: 100px;" class="body2">
							                {{item.leveName}}</td>
							            <td style="border:1px solid #e8e8e8;width: 120px;height: 100px" class="body3">
							                <div style="border-bottom:1px solid #e8e8e8;width: 120px;height: 50px" class="body1 juzhong">
												{{l('Plan')}}
											</div>
											<div style="width: 120px;height: 50px" class="body1 juzhong">
												{{l('Actual')}}
											</div>
										</td>
							            <td v-for="(items, index) in item.planCount" align="center" style="border:1px solid #e8e8e8;width: 110px;height: 100px" class="body3">
											<div style="border-bottom:1px solid #e8e8e8;width: 110px;height: 50px" class="body1 juzhong">
												{{items}}
											</div>
											<div style="width: 110px;height: 50px" class="body1 juzhong">
												{{item.aclCount[index]}}
											</div>
							            </td>
							        </tr>
									<tr>
									    <td style="border:1px solid #e8e8e8;width: 160px;height: 100px;" class="body2">
									        {{l('Total')}}</td>
									    <td style="border:1px solid #e8e8e8;width: 120px;height: 200px" class="body4">
									        <div style="border-bottom:1px solid #e8e8e8;width: 120px;height: 50px" class="body1 juzhong">
												{{l('Plan')}}
											</div>
											<div style="border-bottom:1px solid #e8e8e8;width: 120px;height: 50px" class="body1 juzhong">
												{{l('Actual')}}
											</div>
											<div style="border-bottom:1px solid #e8e8e8;width: 120px;height: 50px" class="body1 juzhong">
												{{l('FinishRate')}}
											</div>
											<div style="width: 120px;height: 50px" class="body1 juzhong">
												{{l('Target')}}
											</div>
										</td>
									    <td v-for="(items, index) in Total.planCount" align="center" style="border:1px solid #e8e8e8;width: 110px;height: 100px" class="body4">
											<div style="border-bottom:1px solid #e8e8e8;width: 110px;height: 50px" class="body1 juzhong">
												{{items}}
											</div>
											<div style="border-bottom:1px solid #e8e8e8;width: 110px;height: 50px" class="body1 juzhong">
												{{Total.aclCount[index]}}
											</div>
											<div style="border-bottom:1px solid #e8e8e8;width: 110px;height: 50px" class="body1 juzhong">
												{{Total.completionRate[index] + '%'}}
											</div>
											<div style="width: 110px;height: 50px" class="body1 juzhong">
												{{(Total.target == null?l('Unset'):Total.target + '%')}}
											</div>
									    </td>
									</tr>
							    </table>
							</div>
						</a-spin>
					</a-col>
                </a-row>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
    import {
        AppComponentBase
    } from "@/shared/component-base";
	import ModalComponentBase from '../../../shared/component-base/modal-component-base';
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        ReportServiceProxy,
		KanBanServiceProxy
    } from '../../../shared/service-proxies';
    import moment from "moment";

    let _this;

    export default {
        name: "Level-Monthly-Miew",
        mixins: [ModalComponentBase],
        components: {
            
        },
        created() {
            _this = this;
            this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
			this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
			this.year = (new Date()).getFullYear()
			this.momentYear = moment(this.year + '-01-01')
			this.fullData(); // 模态框必须,填充数据到data字段
            window.addEventListener('resize', this.changeHeight);
            this.changeHeight();
			this.getDataMaintenance();
        },
        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,
                isLoading: false,
				isOpen: false,
				momentYear: undefined,
				year: undefined,
				month: [1,2,3,4,5,6,7,8,9,10,11,12],
				styObj: {
				    height: 0
				},
				LevelList: [],
				Total: {},
				FakeData: []
            }
        },
        methods: {
            moment,
			
			//div高度
			changeHeight() {
			    this.styObj.height = window.innerHeight - 199 + 'px';
			},
			
			//是否打开选择
			openChange(e) {
			    if (e) {
			        this.isOpen = true;
			    } else {
			        this.isOpen = false;
			    }
			},
			
			//下拉选中年份
			handleChange(value) {
			    this.year = value.format('YYYY');
				this.momentYear = value;
			    this.isOpen = false;
			    this.getData();
			},
			
			//目标值
			getDataMaintenance() {
				this.KanBanServiceProxy.getAll(
					
				).finally(() => {
				    
				}).then(res => {
					this.FakeData = res;
					this.getData();
				})
			},
			
			getData() {
				this.isLoading = true;
				this.ReportServiceProxy.getAuditTaskLevelReport(
					this.year
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.LevelList = [];
					
					if (res.length <= 0) {
					    this.LevelList = [];
					    return;
					}
					
					for(let i in res[0].level) {
						let data = {
							leveName: res[0].level[i].levelName,
							planCount: [],
							aclCount: [],
							completionRate: []
						};
						this.LevelList.push(data);
					}
					
					for(let i in this.LevelList) {
						for(let l in res) {
							for(let a in res[l].level) {
								if(this.LevelList[i].leveName == res[l].level[a].levelName) {
									this.LevelList[i].planCount.push(res[l].level[a].planCount);
									this.LevelList[i].aclCount.push(res[l].level[a].aclCount);
									this.LevelList[i].completionRate.push(Math.round(res[l].level[a].completionRate * 10000) / 100);
								}
							}
						}
					}
					
					this.Total = this.LevelList[this.LevelList.length - 1];
					for(let i in this.FakeData) {
						if(this.FakeData[i].code == "Level-plan-completion-rate") {
							this.Total.target = this.FakeData[i].value;
						}
					}
				});
			}
        }
    }
</script>

<style scoped>
    .body {
        min-width: 80px;
        min-height: 50px;
    }

    .body1 {
        min-width: 100px;
        min-height: 50px;
    }
	
	.body2 {
	    min-width: 100px;
	    min-height: 50px;
	}
	
	.body3 {
	    min-width: 100px;
	    min-height: 100px;
	}
	
	.body4 {
	    min-width: 100px;
	    min-height: 200px;
	}
	
	.juzhong {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	th:nth-child(1){
        position: sticky;
        left: 0;
		top: 0;
		z-index: 2;
		background: #ffffff;
    }
	
    td:nth-child(1){
        position: sticky;
        left: 0;
		z-index: 1;
		background: #ffffff;
    }
	
	th:nth-child(2){
	    position: sticky;
	    left: 100px;
		top: 0;
		z-index: 2;
		background: #ffffff;
	}
	
	td:nth-child(2){
	    position: sticky;
	    left: 100px;
		z-index: 1;
		background: #ffffff;
	}
</style>